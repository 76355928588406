function initializeFlash(duration = 5000) {
  $(document).on('click', '.flash #close-flash', function (e) {
    e.preventDefault();
    $(e.target)
      .closest('.flash')
      .fadeOut(500, function () {
        this.remove();
      });
  });

  if ($('.flash').length > 0) {
    setTimeout(function () {
      $('.flash').fadeOut(500, function () {
        this.remove();
      });
    }, duration);
  }
}

export function flash(type, message, duration) {
  /* Clear existing flashes */
  if ($('.flash').length > 0) {
    $('.flash').remove();
  }

  const flashElement = document.createElement('div');
  flashElement.className = `flash ${type} shadow`;
  flashElement.innerHTML = `${message}</span> <div class='close-flash-container'><a href='#' id='close-flash'>CLOSE</a></div>`;
  document.body.insertBefore(flashElement, document.body.children[0]);
  initializeFlash(duration);
}

/* Parses out standard errors response from Rails, and flashes it */
export function flashErrors(jqXHR, defaultMessage) {
  let errorMessage = defaultMessage;

  if (jqXHR.status === 422) {
    const errorsArray = jqXHR.responseJSON.errors;
    if (errorsArray && errorsArray.length !== 0) {
      errorMessage = `${errorMessage} ${errorsArray[0]}`;
    }
  }

  flash('error', errorMessage);
}

$(function () {
  initializeFlash();
});
